// if you need to add custom js put it in here
$(window).load(function () {
    $('.HButton').bootstrapSwitch();
    $('.about-content-wrapper').matchHeight();
    $('.blogTitle').matchHeight();
    $('.blogDescription').matchHeight();

    $('.custom-stock-message').each(function () {
        var stringA = $(this).text()
        if (stringA.indexOf("in stock") != -1) {
            $(this).addClass("whiteMessage");
        } else {
            $(this).removeClass("whiteMessage");
        }
    });
    // product search handler
    $("#prod-search-tbx-mobile").autocomplete({
        source: root + 'Products/AutoComplete',
        minLength: 2,
        select: function (event, ui) {
            window.location.href = ui.item.url;
        }
    });
    $('#prod-search-tbx-mobile').keypress(function (e) {
        if (e.which == 13) {
            $('#prod-search-btn-mobile').click();
        }
    });
    $('#prod-search-btn-mobile').click(function () {
        if ($('#prod-search-tbx-mobile').val() != "") {
            var str = $('#prod-search-tbx-mobile').val();
            window.location.href = root + "Products/Search?search=" + encodeURIComponent(str);
        }
        return false;
    });
    $('.pf-request-check-stock').on("click", function (e) {
        $('.pf-request-check-stock').toggleClass("form-displayed");
    });
    $('.bag-closer.pf-proceed-checkout-btn').click(function () {
        if ($('#top-cart-holder').is(':hidden')) {
            $('#top-cart-holder').slideDown();
        }
        else {
            $('#top-cart-holder').slideUp();
        }
        window.scrollTo(0, 0);
    });
    $('.pf-multi-level-sub-nav.w-dropdown-list').hover(function (e) {
        e.preventDefault();
        if ($(window).width() < 768)
            return;

        var openClass = 'w--open';
        if (e.type === 'mouseenter') {
            $(this).siblings('.pf-multi-level-heading').addClass(openClass);
        } else if (e.type === 'mouseleave') {
            $(this).siblings('.pf-multi-level-heading').removeClass(openClass);
        }
    });

    InitFeaturedProductSlider(true);

    var resizeRecalculating = false;
    $(window).on('resize orientationchange', function () {
        if (!resizeRecalculating) {
            resizeRecalculating = true;
            setTimeout(function () {
                InitFeaturedProductSlider(false);
                resizeRecalculating = false;
            }, 500);
        }
    });
    $('.home-featured-products-tabs .tab').click(function () {
        setTimeout(function () {
            InitFeaturedProductSlider(true);
        }, 500);
    });
    $('.featured-dropdown .featured-home-dropdown').change(function () {
        //$('.home-featured-products-tabs .w-tab-content').hide();
        $('.home-featured-products-tabs .w-tab-content .w--tab-active').removeClass('w--tab-active');
        //$('#' + $(this).val()).show();
        $('#' + $(this).val()).children().addClass('w--tab-active');
        setTimeout(function () {
            InitFeaturedProductSlider(true);
        }, 500);
    });
    $(document).on('click', '.pf-quick-view-btn', function () {
        var btn = $(this);
        var id = btn.data("id");  
        
        $.ajax({
            url: root + "Custom/GetQuickView",
            async: false,
            type: 'POST',
            data: { productId: id },
            success: function (rsp) {
                SetPlatformModalContent("Quick View", rsp);
                OpenPlatformModal("650px");
            }
        });
        return false;
    });
    $('#checkout-form #cartNote').webuiPopover({ content: 'Please do not request collections in this field, you must book in directly with info@mcgowanrutherford.co.uk', trigger: 'manual', multi: true, placement: 'top' });
    $('#checkout-form #cartNote').keypress(function () {
        $('#checkout-form #cartNote').webuiPopover('show');
    });
});

function InitFeaturedProductSlider(reinitialize) {
    $('.w--tab-active .featured-products').each(function () {
        var $slider = $(this);
        /* Initializes a slick carousel only on tablet and mobile screens */
        // slick on tablet
        if ($(window).outerWidth() > 1024) {
            if ($slider.hasClass('slick-initialized')) {
                $slider.slick('unslick');
            }
        }
        if ($(window).outerWidth() <= 1024) {
            if (reinitialize && $slider.hasClass('slick-initialized')) {
                $slider.slick('unslick');
            }
            if (reinitialize || !$slider.hasClass('slick-initialized')) {
                $('.w--tab-active .featured-products').slick({
                    slidesToShow: 2,
                    rows: 0,
                    prevArrow: "<div class=\"pf-slider-button pf-slider-button-prev\"><i class=\"fas fa-chevron-left\"></i></div>",
                    nextArrow: "<div class=\"pf-slider-button pf-slider-button-next\"><i class=\"fas fa-chevron-right\"></i></div>",
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2
                            }
                        },
                        {
                            breakpoint: 450,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]
                });
            }
        }
    });
    $('.featured-products .product-info-match-height').matchHeight();
}
//To be turned on if useful:


////// compact header when opening and closing the expanded search functionality. 
//$('.search-opener').click(function () {
//    $(window).scrollTop(0);
//    $('#search-div-block-compact').show();
//    $('.logo-section').hide();
//});
//$('.search-close').click(function () {
//    if (document.body.scrollTop < 50 || document.documentElement.scrollTop < 50) {
//        $('#search-div-block-compact').hide();
//        $('.logo-section').show();
//    }
//});


///// Hide promotional strip when using mobile nav. 
//$('.hamburger-menu-button').click(function () {
//    console.log($(this).is('.w--open'));
//    if ($(this).is('.w--open')) {
//        $('.pf-promotion-container').hide()
//    } else {
//        $('.pf-promotion-container').show()
//    }
//});

////// CoachHouse's compact header functionality
//window.onscroll = function () { scrollFunction() };
//function scrollFunction() {
//    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
//        $('#search-div-block-compact').show();
//        $('.logo-section').hide();
//    } else {
//        if (!$('.search-section').is(':visible')) {
//            $('#search-div-block-compact').hide();
//            $('.logo-section').show();
//        }
//    }
//}


/////// additional mac/ios specific styling required. add the class mac-os to the required element
//if (navigator.userAgent.indexOf('Mac') > 0) {
//    $('.pf-new-product-tag').each(function () {
//        $(this).find('span').addClass('mac-os')
//    });
//    $('.pf-sale-product-tag').each(function () {
//        $(this).find('span').addClass('mac-os')
//    });
//}