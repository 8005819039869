Platform.Deferred.execute(setProductCommonScript);

function wishListBtnClickHandler() {
    var $modal = $('[data-remodal-id=wishlist-modal]').remodal({
        hashTracking: false
    });
    // favourites handlers
    $(document).on('click', '.add-to-wishlist-btn', function () {

        var validOptions = true;
        $('.productOptions').each(function () {
            if ($(this).length > 0) {
                if ($(this).val() == 0) {
                    $(this).next('.productOptionWarn').fadeIn();
                    $(this).addClass("highlight");
                    prodOptsOk = false;
                    validOptions = false;
                }
            }
        });

        if (validOptions) {
            $modal.open();
            wishListsChecked = $(this).data("wishlists-checked");
            if (!wishListsChecked)
                wishListsChecked = [];

            CheckWishListCheckboxes(wishListsChecked);

            var $wishListModal = $('.pf-modal-wishlist');
            $wishListModal.find('.container-wishlist').css('width', '');

            var imgLink = $(this).closest(".pf-product-list-item").find(".pf-list-item-img").attr("src")
                || $(".pf-product-main-image-wrapper").find(".img:first").attr("src");
            $wishListModal.find(".wishlist-picture-img").attr("src", imgLink);
            var id = $(this).data("id");
            $wishListModal.find(".add-to-favorites-btn").data("product-ids", id);
        }
    });
}


function setProductCommonScript() {

    wishListBtnClickHandler();
    var $modal = $('[data-remodal-id=wishlist-modal]').remodal({
        hashTracking: false
    });
    $(document).on('click', '.pf-add-all-to-wish-list-btn', function () {
        var $productsTarget = $($(this).data('products-target'));
        var productIds = $productsTarget.find('.pf-product-list-item').map(function () {
            return $(this).data('id');
        }).get();

        CheckWishListCheckboxes([]);
        var imgLink = $('#pf-gallery-image-products').find(".pf-product-list-item").first().find(".pf-list-item-img").attr("src")
            || $(".pf-product-main-image-wrapper").find(".img:first").attr("src");
        var $wishListModal = $('.pf-modal-wishlist');
        $wishListModal.find(".wishlist-picture-img").attr("src", imgLink);
        //$wishListModal.find('.container-wishlist').css('width', '100%');
        $wishListModal.find(".add-to-favorites-btn").data("product-ids", productIds.join(','));

        $modal.open();
        return false;
    });

    $(document).on('click', '.create-wish-List-btn', function () {
        $(this).closest(".container-wishlist").find(".pf-wishlist-create-new").removeClass('hidden');
        $(this).closest(".wishlist-main-buttons").addClass('hidden');
        $(".pf-create-wish-list").focus();
    });

    $('.pf-create-wish-list').bind('keypress', function (e) {
        if (e.keyCode == 13)
            $(".post-new-wishlist-btn").trigger("click");
    });

    $(document).on('click', '.post-new-wishlist-btn', function () {
        
        var newListName = $(this).closest(".pf-wishlist-create-new").find(".pf-create-wish-list").val();

        $.post(root + "UserAccount/AddNewWishList", {
            newListName: newListName
        }, function (data) {
            if (data.result == "ok") {
                AddNewWishListCheckbox(data.wishListName, data.wishlistId);
                $(".pf-wishlist-create-new").addClass('hidden');
                $(".wishlist-main-buttons").removeClass('hidden');
                $(".pf-wishlist-create-new").addClass('hidden');
                $(".create-wish-List-btn").css('display', 'inline-block');
                $(".pf-wishlist-create-new").find(".pf-create-wish-list").val("");
            }
            else {
                alert("Error: " + data.error);
            }
        }, "json");
    });
    $(document).on('click', '.cancel-new-wishlist-btn', function () {
        $(".pf-wishlist-create-new").addClass('hidden');
        $(".wishlist-main-buttons").removeClass('hidden');
        $(".pf-wishlist-create-new").addClass('hidden');
        $(".create-wish-List-btn").css('display', 'inline-block');
    });

    $(document).on('click', '.add-to-favorites-btn', function () {
        var loggedIn = $('#pf-product-details-page').data("logged-in") || $('.pf-product-list-item').data("logged-in");
        if (loggedIn == "True") {
            var btn = $(this);
            var isVariant = btn.data("is-variant");
            var productIds = btn.data("product-ids").toString().split(',');
            var wishlistIds = [];
            $(this).closest(".container-wishlist").find("input[class ='wishlistCheckbox']:checked").each(function () {
                wishlistIds.push($(this).val());
            });
            var isFav = btn.data("is-fav");
            var optionsAdv = [];
            // check if this is a product with components
            var hasComponents = $('#pf-product-details-page').data('product-has-components');
            if (hasComponents === "True") {
                // check all selected otherwise cannot add to cart
                if (!CheckComponentsSelected()) {
                    alert("Please select from the available product options");
                    return false;
                }
                // if all selected then setup the object to pass into the cart
                optionsAdv = GetSelectedOptionsAdv();
            }

            if (!wishlistIds || wishlistIds === [] || wishlistIds.length === 0) {
                if (productIds.length === 1) {
                    $.post(root + "UserAccount/RemoveProductFromFavourites", {
                        id: productIds[0],
                        isVariant: isVariant,
                        optionsAdv: optionsAdv
                    }, function (data) {
                        if (data.result == "ok") {
                            $(document).trigger('removedFromFavs.product.platform', data);

                            ReturnWishlistsCheckedAndSwitchFavIcon(wishlistIds, productIds[0]);

                            $modal.close();
                        }
                        else {
                            alert("Error: " + data.error);
                        }
                    }, "json");
                }
            } else {
                if (productIds.length === 1) {
                    // if any options make sure all have a selection made
                    var prodOptsOk = true;
                    $('.productOptions').each(function () {
                        if ($(this).length > 0) {
                            if ($(this).val() == 0) {
                                $(this).next('.productOptionWarn').fadeIn();
                                $(this).addClass("highlight");
                                prodOptsOk = false;
                                return false;
                            }
                        }
                    });

                    if (prodOptsOk == false) {
                        return false;
                    }

                    var opts = [];
                    $('.productOptions').each(function (i, o) {
                        var opt = {};
                        var name = $(this).attr('id');
                        name = name.replace("option-select-", "");
                        opt.index = i;
                        opt.name = name;
                        opt.value = $(this).val();
                        opts.push(opt);
                    });

                    $.post(root + "UserAccount/AddProductToFavourites", {
                        id: productIds[0],
                        isVariant: isVariant,
                        options: opts,
                        optionsAdv: optionsAdv,
                        wishListIds: wishlistIds
                    }, function (data) {
                        if (data.result == "ok") {
                            $(document).trigger('addedToFavs.product.platform', data);
                            ReturnWishlistsCheckedAndSwitchFavIcon(wishlistIds, productIds[0]);
                            $modal.close();
                        }
                        else {
                            alert("Error: " + data.error);
                        }
                    }, "json");
                } else {
                    $.post(root + "UserAccount/AddProductsToWishLists", {
                        productIds: productIds,
                        wishListIds: wishlistIds
                    }, function (data) {
                        if (data.result === 'ok' || data.result === 'multiple') {
                            $(document).trigger('addedToFavs.product.platform', data);
                            $modal.close();
                        } else {
                            alert("Error: " + data.error);
                        }
                    }, "json");
                }
            }
        }
        else {
            loginModal.open();
        }
    });

}


function ReturnWishlistsCheckedAndSwitchFavIcon(wishListsChecked, productId) {
    var favbutton = $('.add-to-wishlist-btn.isfav-icon[data-id="' + productId + '"]');
    var notFavbutton = $('.add-to-wishlist-btn.isnotfav-icon[data-id="' + productId + '"]');
    var checkedstr = "[" + wishListsChecked.toString() + "]";
    if (wishListsChecked.length == 0) {
        favbutton.addClass('hidden')
        favbutton.data("wishlists-checked", checkedstr);
        notFavbutton.removeClass('hidden')
        notFavbutton.data("wishlists-checked", checkedstr);
    } else {
        favbutton.removeClass('hidden')
        favbutton.data("wishlists-checked", checkedstr);
        notFavbutton.addClass('hidden')
        notFavbutton.data("wishlists-checked", checkedstr);
    }
}



function CheckWishListCheckboxes(wishListsChecked) {

    $.each($("input[class ='wishlistCheckbox']"), function () {
        var id = $(this).val();
        if (wishListsChecked.includes(Number(id))) {
            $(this).prop('checked', true);
        } else {
            $(this).prop('checked', false);
        }
    });

}


function AddNewWishListCheckbox(wishlistName, wishlistId) {
    if ($(".wishlist-section").length) {
        // on the wishlist page. need to refresh rather than append.
        location.reload();
    } else {
        $(".wishlistCheckboxList").append("<div class='checkbox' align='right'><input type='checkbox' id='wishlist" + wishlistId + "' class='wishlistCheckbox' name='wishlist" + wishlistId + "' value='" + wishlistId + "' checked='checked' /><label for= 'wishlist" + wishlistId + "' ><span class='pull-left'>" + wishlistName + "</span></label></div>");
    }
}


// currently used for products with components only, once all options have been selected we set the add or remove favs buttons by checking via ajax
function SetFavButtons(optionsAdv) {
    var loggedIn = $('#pf-product-details-page').data('logged-in');
    if (loggedIn === "True") {
        $.post(root + "Products/CheckProductIsFavOptionsAdv", {
            productId: $('#pf-product-details-page').data('product-id'),
            optionsAdv: optionsAdv
        }, function (data) {
            if (data.result === 0) {
                var isFav = data.isFav;
            } else {
                alert("Error: " + data.error);
            }
        }, "json");
    }
}


$(document).ready(function () {
    $('#pf-currency-selector').on('change', function (e) {
        var currencyId = $(this).val();
        $.post(root + "Products/SetSessionCurrency", {
            currencyId: currencyId
        }, function (response) {
            if (response.result === "ok") {
                window.location.reload();
            } else {
                alert("Failed");
            }
        }, 'json');
    });
});

